import React from 'react';
import EmployeeBlock from 'blocks/EmployeeBlock';
import cx from 'classnames';
import Block from 'components/Block';
import Container from 'components/Container';
import Expandable from 'components/Expandable';
import FloatContent from 'components/FloatContent';
import Swoosh from 'components/Swoosh';
import TabsWithText from 'components/TabsWithText';

import {
  BodyType,
  EmbedBlockType,
  FaqEntry,
  ImageBlockType,
  MediaBlockType,
} from '../../../types/Lesson';
import Button from '../../components/Button';
import Tooltip from '../../components/Tooltip';
import Typography from '../../components/Typography';
import { createHoverMonitor } from '../../utils';
import styles from './BlockRenderer.module.css';

// blocks that have own containers, thus needs no surrounding container
const CONTAINER_BLOCKS = ['shape_container', 'swoosh'];

interface BlockRendererProps {
  body: BodyType[];
  className?: string;
  nested?: boolean;
  pageType: Context['pageType'];
}

interface Context {
  pageType: 'landing' | 'lesson';
}

type Renderer<T = string | ImageBlockType | Record<string, unknown>> = (
  block: BodyType<T>,
  ctx: Context,
) => React.ReactElement;

const hover = createHoverMonitor();

/**
 * Blockrenderer takes an array of blocks from wagtail and renders these using react
 * @param body array of wagtail blocks
 */
const BlockRenderer: React.FC<BlockRendererProps> = ({
  pageType,
  body = [],
  nested = false,
  className,
}) => {
  // Since this render potentially could be infinitely heavy it's a good idea to memoize it.
  const [highlightTarget, setHighlightTarget] = React.useState<Element>();
  const [highlightText, setHighlightText] = React.useState<string>('');
  const p = React.useMemo(
    () => body?.map(b => blockToComponent(b, nested, { pageType }, className)),
    [pageType, body, nested],
  );

  return (
    <>
      <Tooltip
        text={highlightText}
        target={highlightTarget}
        onClose={() => setHighlightTarget(undefined)}
      />
      <article
        onClick={event => {
          if (event.target instanceof HTMLSpanElement) {
            if (event.target.dataset.highlight) {
              setHighlightText(event.target.dataset.highlight);
              setHighlightTarget(event.target);
            }
          }
        }}
        onMouseOver={event => {
          if (!hover.isEnabled) {
            return;
          }
          if (event.target instanceof HTMLSpanElement) {
            if (event.target.dataset.highlight && event.target != highlightTarget) {
              setHighlightText(event.target.dataset.highlight);
              setHighlightTarget(event.target);
              const handleLeave = e => {
                if (e.target !== highlightTarget) {
                  e.target.removeEventListener('mouseleave', handleLeave);
                }
                setHighlightTarget(undefined);
              };
              event.target.addEventListener('mouseleave', handleLeave);
            }
          }
        }}
        className={cx(styles.root, className)}
      >
        {p}
      </article>
    </>
  );
};

// renderers
const contentRenderer: Renderer = (block, ctx) => (
  <Typography
    variant={ctx.pageType === 'lesson' ? 'md-serif' : 'md'}
    key={block.id}
    component="section"
    dangerouslySetInnerHTML={{
      __html: block.value,
    }}
  />
);

const tabsWithContentRenderer: Renderer<any> = (block, ctx) => (
  <TabsWithText
    title={block.value.title}
    subtitle={block.value.subtitle}
    tabs={block.value.tabs.map(v => ({
      title: v.button_title,
      content: (
        <BlockRenderer
          pageType={ctx.pageType}
          nested={true}
          body={v.body}
          className={styles.tabsWithContentRoot}
        />
      ),
    }))}
  />
);

const imageRenderer: Renderer<any> = block => {
  const img = block.value as ImageBlockType;
  return <img alt={img.title} key={block.id} width="100%" src={img.url} />;
};

const blockQuoteRenderer: Renderer<string> = block => (
  <Typography
    key={block.id}
    // This is needed because Typography has a class that inherits color
    style={{ color: '#6a6a6a' }}
    component="blockquote"
    variant="blockquote"
  >
    {block.value}
  </Typography>
);

const faqRenderer: Renderer<any> = block => {
  const q = block.value.questions as FaqEntry[];

  return (
    <>
      {!block.value.title ? null : (
        <Typography variant="heading" component="h2">
          {block.value.title}
        </Typography>
      )}
      {q.map(({ question, answer }, i) => (
        <Expandable key={i} title={question}>
          {answer}
        </Expandable>
      ))}
    </>
  );
};

type ExpandableContentList = { header: string; content: BodyType[] }[];
const expandableContentListRenderer: Renderer<ExpandableContentList> = (block, ctx) => {
  return (
    <div className={styles.expandableContentList}>
      {block.value.map((v, i) => {
        return (
          <Expandable contentPadding={false} key={i} title={v.header}>
            <BlockRenderer nested={true} body={v.content} pageType={ctx.pageType} />
          </Expandable>
        );
      })}
    </div>
  );
};

const titleRenderer: Renderer<string> = block => (
  <Typography key={block.id} component="h1" variant="hero" raw>
    {block.value}
  </Typography>
);

const embedRenderer: Renderer<any> = block => {
  const embed_value = block.value as unknown as EmbedBlockType;

  do {
    if (/^https?:\/\/vimeo/.test(embed_value.url)) {
      const w = embed_value.html.match(/width="(\d*)"/)?.[1];
      const h = embed_value.html.match(/height="(\d*)"/)?.[1];
      const width = parseInt(w ?? 'NOT A VALID NUMBER');
      const height = parseInt(h ?? 'NOT A VALID NUMBER');

      if (!isFinite(width) || !isFinite(height)) {
        // It is what it is
        break;
      }

      const html = embed_value.html
        .replace(/width="\d*"/, '')
        .replace(/height="\d*"/, '')
        .replace(/\<iframe/, "<iframe style='width: 100%; height: 100%' ")
        .replace(/^\s*<div>((?:.|\s)+)<\/div>\s*$/, '$1');

      return (
        <div
          style={{ position: 'relative', width: '100%', paddingTop: `${(height / width) * 100}%` }}
        >
          <div
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
        </div>
      );
    }
  } while (false);

  return (
    <Typography
      key={block.id}
      component="section"
      align="center"
      className={`block-embed ${styles.embedWrapper}`}
      dangerouslySetInnerHTML={{ __html: embed_value.html }}
    />
  );
};

const containerRenderer: Renderer<any> = (block, ctx) => {
  const { color, body } = block.value;
  const children = <BlockRenderer pageType={ctx.pageType} body={body} nested={true} />;
  return (
    <Block key={block.id} background={color}>
      <Container>{children}</Container>
    </Block>
  );
};

const swooshRenderer: Renderer<any> = block => {
  const { mirror_x, mirror_y, from_color, to_color } = block.value;
  return (
    <Swoosh key={block.id} mirrorX={mirror_x} mirrorY={mirror_y} from={from_color} to={to_color} />
  );
};

const mediaRenderer: Renderer<any> = block => {
  const media_value = block.value as unknown as MediaBlockType;
  const type = media_value.type.split('/')[0];

  return (
    <div key={block.id}>
      <Typography raw={true} component="p" variant="small" align="center">
        {media_value.title}
      </Typography>
      <Typography
        component="section"
        align="center"
        className={'block-media ' + styles.blockSection}
      >
        {type === 'audio' && (
          <audio controls>
            <source src={media_value.url} type={media_value.type} />
          </audio>
        )}
        {type === 'video' && (
          <video controls>
            <source src={media_value.url} type={media_value.type} />
          </video>
        )}
      </Typography>
    </div>
  );
};

const employeeListRenderer: Renderer<any> = block => {
  const { title, employees } = block.value;
  return (
    <>
      {title && (
        <Typography variant="heading" component="h3" align="center">
          {title}
        </Typography>
      )}
      <div className={styles.employeeWrapper}>
        {employees.map((em: Author, i: number) => (
          <EmployeeBlock key={i} {...em} />
        ))}
      </div>
    </>
  );
};

const employeeRenderer: Renderer<any> = block => {
  const { avatar, name, profession } = block.value;
  return <EmployeeBlock key={block.id} avatar={avatar} name={name} profession={profession} />;
};

const buttonRenderer: Renderer<any> = block => {
  const { link, link_text, variant } = block.value;
  return (
    <Button variant={variant} href={link} key={block.id} className={styles.blockButton}>
      {link_text}
    </Button>
  );
};

const floatImageTextRenderer: Renderer<any> = (block, ctx) => {
  const { text, position, image } = block.value;

  return (
    <FloatContent
      floatContent={<img style={{ width: '100%' }} src={image.url} alt={image.text} />}
      position={position}
    >
      {contentRenderer({ value: text, id: text, type: 'content' }, ctx)}
    </FloatContent>
  );
};

// key value list of known BlockTypes with it's corresponding renderer
const RENDERERS: Record<string, Renderer<any>> = {
  content: contentRenderer,
  text: contentRenderer,
  float_image_text: floatImageTextRenderer,
  image: imageRenderer,
  block_quote: blockQuoteRenderer,
  title: titleRenderer,
  embed: embedRenderer,
  media: mediaRenderer,
  employee: employeeRenderer,
  tabbed_info: tabsWithContentRenderer,
  employees: employeeListRenderer,
  shape_container: containerRenderer,
  swoosh: swooshRenderer,
  faq: faqRenderer,
  button: buttonRenderer,
  expandable_content_list: expandableContentListRenderer,
};

export function blockToComponent(
  block: BodyType,
  nested: boolean,
  context: Context,
  className?: string,
) {
  const renderer = RENDERERS[block.type];
  const hasOwnContainer = CONTAINER_BLOCKS.includes(block.type);
  if (renderer) {
    const inner = renderer(block, context);
    if (hasOwnContainer || nested) {
      return inner;
    }

    return (
      <Container>
        <Block padding="bottom-only" className={className}>
          {inner}
        </Block>
      </Container>
    );
  }
  // unknown block
  console.warn(
    '`<BlockRenderer/>` Encountered block without corresponding renderer for block type: ',
    block.type,
  );
  return null;
}

export default BlockRenderer;
